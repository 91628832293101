import Vue from 'vue'
import App from './App.vue'
import UUID from "vue-uuid";
import '@/mixins/generalMixin'
import '@/mixins/validationMixin'
import '@/mixins/validationClassesMixin'

//API-REST
import axios from 'axios'
import VueAxios from 'vue-axios'

//App Router
import routerInstance from '@/router'

//Internazionalitation
import i18n from "./i18n";

//Bootstrap
import BootstrapVue from "bootstrap-vue"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-vue/dist/bootstrap-vue.css"

//Material Design Icons 
import MdiIcon from './components/MdiIcon.vue';

//Environment configuration
import {setEnvConfig} from '@/config/envConfig.js'

// CookieBot
import '@/js/cookiebot'

//Constants
export const bus = new Vue();

Vue.use(VueAxios, axios)
Vue.use(BootstrapVue)
Vue.use(UUID)
Vue.component('mdi-icon', MdiIcon)

Vue.config.productionTip = false

const host = window.location.host;
const isValidEnvironment = setEnvConfig(host);

if (isValidEnvironment) {
    new Vue({
        i18n,
        router: routerInstance,
        render: h => h(App),
    }).$mount('#app')
}
