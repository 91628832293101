import Vue from 'vue'

Vue.mixin({
    methods: {
        capitalizeFirstLetter: str => str.charAt(0).toUpperCase() + str.slice(1),
        pushGTM(event, campo_funnel, paso, value = "", tipo_seguro = "", allPropsRequired = false) {
            window.dataLayer = window.dataLayer || [];
            if ((allPropsRequired && value != "" && tipo_seguro != "") || allPropsRequired == false) {
                var data = {
                    'event': event,
                    'campo_funnel': campo_funnel,
                    'paso': paso
                }
                if (value != "") {
                    data.value = value
                }
                if (tipo_seguro != "") {
                    data.tipo_seguro = tipo_seguro
                }
                window.dataLayer.push(data)
            }
        },
        getCookie(cname) {
            var name = cname + "=";
            var decodedCookie = decodeURIComponent(document.cookie);
            var ca = decodedCookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        },
        pushUTM() {
            window.dataLayer = window.dataLayer || []
            var self = window.location.toString();
            var querystring = self.split("?");
            var utm_source = ''
            var utm_medium = ''
            var utm_campaign = ''
            if (querystring.length > 1) {
                var pairs = querystring[1].split("&");
                for (let i in pairs) {
                    var keyval = pairs[i].split("=");
  
                    if(keyval[0] == 'utm_source') utm_source = keyval[1]
                    if(keyval[0] == 'utm_medium') utm_medium = keyval[1]
                    if(keyval[0] == 'utm_campaign') utm_campaign = keyval[1]
                    
                }    
            }
            if(utm_source.length !== 0){
                let expirationTime = 60*60*24*30;
                document.cookie = "utmcross=&utm_source=" + utm_source + "&utm_medium=" + utm_medium + "&utm_campaign=" + utm_campaign + "; max-age=" + expirationTime + "; path=/"
            } 
        },
        getUTMData(){

            var querystring = this.getCookie('utmcross')
            var utm_source = ''
            var utm_medium = ''
            var utm_campaign = ''

            var pairs = querystring.split("&");
            for (let i in pairs) {
                
                var keyval = pairs[i].split("=");
  
                if(keyval[0] == 'utm_source') utm_source = keyval[1]
                if(keyval[0] == 'utm_medium') utm_medium = keyval[1]
                if(keyval[0] == 'utm_campaign') utm_campaign = keyval[1]
                      
            }
            var data = {  
                'utm_source': utm_source,  
                'utm_medium': utm_medium,
                'utm_campaign': utm_campaign
            }
            
            return data;
        },
        pushGTMOffer(event, id, objectName, name, creative='', link='') {
            window.dataLayer = window.dataLayer || [];
            var data = {  
                'event': event,  
                'ecommerce': { }
            }

            data['ecommerce'][objectName] =  {
                'promotions': [
                    {
                        'id': id,  // ID o Name obligatorio.
                        'name': name,
                        'creative': creative,  // En caso de que exista nombre de la creatividad.
                        'position': 'pop-up' // Posición del descuento o descripción.
                    }
                ]
            }

            window.dataLayer.push(data)

            if (link != '' && link != '#') {
                var data2 = {
                    'eventCallback': function() {document.location = link;    }
                }

                window.dataLayer.push(data2)
            }
        },

        pushGTMPolicyTypeObject(event, name, price) {
            window.dataLayer = window.dataLayer || [];
            // window.dataLayer.push({ ecommerce: null });
            var data = {  
                'event': event,  
                'insurance_type': name, //Opción que ha modificado
                'insurance_price': price // Precio del seguro que se ha generado
            }

            window.dataLayer.push(data)
        },

        pushGTMProducts(event, insurances = []) {
            window.dataLayer = window.dataLayer || [];
            var data = {  
                'event': event,  
                'ecommerce': {
                    'currencyCode': 'EUR',
                    'impressions': insurances
                }
            }

            window.dataLayer.push(data)
        },


        pushGTMPrices(event, price, insurances = []) {
            window.dataLayer = window.dataLayer || [];
            var data = {  
                'event': event,
                'product_price': price,
                'ecommerce': {
                    'currencyCode': 'EUR',
                    'impressions': insurances
                }
            }

            window.dataLayer.push(data)
        },

        pushGTMInsuranceClick(event, url, insurance = {}) {
            window.dataLayer = window.dataLayer || [];
            var data = {  
                'event': event,  
                'ecommerce': {      
                    'click': {        
                        'actionField': {'list': 'Home'},        
                        'products': insurance,     
                    }
                },
                'eventCallback': function() {  document.location = url }
            }

            window.dataLayer.push(data)
        },

        pushGTMUserID(phone) {
            window.dataLayer = window.dataLayer || [];
            const identifier = document.cookie.match('(^|;)\\s*_ga\\s*=\\s*([^;]+)') ?.pop() || null;
            const userId = window.dataLayer.filter(function(obj) { return obj.event === 'User ID'; });
            if (userId.length == 0 || (userId.length == 1 && identifier != userId[0].user_id)) {

                const NAMESPACE = "65f9af5d-f23f-4065-ac85-da725569fdca";
                const newID = this.$uuid.v5(phone, NAMESPACE)
                window.dataLayer.push({
                    'user_id': newID
                });
            }
        },
        pushGTMEmail(email) {
            window.dataLayer = window.dataLayer || [];
            const NAMESPACE = "65f9af5d-f23f-4065-ac85-da725569fdca";
                const newID = this.$uuid.v5(email, NAMESPACE)
                window.dataLayer.push({
                    'email': newID
                });
        }
    }
})
